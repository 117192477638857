.app {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 40px auto 0;
  width: 400px;
  padding: 20px;
  border: 2px solid #222;
  border-radius: 8px;
}

h1 {
  margin: 0;
}

.counter {
  font-size: 60px;
}

.controls {
  display: flex;
  gap: 20px;
}

button {
  background: dodgerblue;
  border: #222;
  margin-right: 8px;
  border: 2px solid #222;
  border-radius: 4px;
  box-shadow: 0 2px #333;
  font-size: 16px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;
  opacity: 1;
}

button:hover {
  opacity: 0.8;
}

button:active {
  opacity: 0.9;
  box-shadow: 0 1px #333;
  transform: translateY(1px);
}
